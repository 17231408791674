import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import SpeakerSingle from "../../src/components/speaker-single";
import { Container, Grid } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SpeakerSingle name="Lolita Chávez" profession="Indígena guatemalteca, activista y feminista defensora de los DDHH (Guatemala)" presentation="Violencias machistas y extractivismo en Abya Yala." youtubeid="lFTcXDJzM-Y" hasspeach="true" skipTo={{
      h: 0,
      m: 12,
      s: 23
    }} mdxType="SpeakerSingle" />
    <Container mdxType="Container">
      <h2>{`Imágenes`}</h2>
      <Grid gap={[2, 3]} sx={{
        gridTemplateColumns: [`repeat(2, minmax(100px, 1fr))`, `repeat(4, minmax(250px, 1fr))`]
      }} mdxType="Grid">
        <p><img parentName="p" {...{
            "src": "lolita-chavez-01.jpg",
            "alt": null
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "lolita-chavez-02.jpg",
            "alt": null
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "lolita-chavez-03.jpg",
            "alt": null
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "lolita-chavez-04.jpg",
            "alt": null
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "lolita-chavez-05.jpg",
            "alt": null
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "lolita-chavez-06.jpg",
            "alt": null
          }}></img></p>
        <p><img parentName="p" {...{
            "src": "lolita-chavez-00.jpg",
            "alt": null
          }}></img></p>
      </Grid>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      